
import Vue from 'vue';
import * as api from '@/api';

export default Vue.extend({
    data() {
        return {
            headers: {
              Authorization: '',
            },
            imgUploadUrl: `${process.env.VUE_APP_FILEURL}api/v1.0/File`,
            f: {} as api.BrandInfo,
            logo: '',
            certifications: [] as api.BrandCertifications[],
            txtVal: 0,
            form: {
              brandId: '',
              imageUrl: '',
              name: '',
              phone: '',
              email: '',
              webSite: '',
              address: '',
              summary: '',
              isManufacturer: true,
              isShow: true,
              certificationIds: [],
              fansCount: 0,
              productCount: 0,
            } as api.BrandInfo,
            logoPath: '',
            rules: {
              name: [
                { required: true, message: '请输入品牌名称', trigger: 'blur' },
              ],
              imageUrl: [
                { required: true, message: '请上传品牌照片', trigger: 'blur' },
              ],
              phone: [
                { required: true, message: '请输入品牌电话', trigger: 'blur' },
                { pattern: /^[0-9-]+$/, message: '电话格式错误', trigger: 'blur' },
              ],
              email: [
                { required: true, message: '请输入品牌邮箱', trigger: 'blur' },
                { pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/, message: '邮箱格式错误', trigger: 'blur' },
              ],
              webSite: [
                { required: true, message: '请输入品牌网址', trigger: 'blur' },
                // eslint-disable-next-line no-useless-escape
                { pattern: /^(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/, message: '网址格式错误', trigger: 'blur' },
              ],
              address: [
                { required: true, message: '请输入品牌地址', trigger: 'blur' },
              ],
              summary: [
                { required: true, message: '请输入品牌描述', trigger: 'blur' },
              ],
            },
        };
    },
    async mounted() {
      await this.$store.dispatch('getToken');
      this.headers.Authorization = this.$store.state.fileToken;
      this.brand();
      this.getCertifications();
    },
    methods: {
      look() {
        // todo: 后期ProductList参数会调整暂时先这样
        this.$router.push({
          name: 'ProductList',
          query: {
            brandId: this.form.brandId,
          },
        });
      },
       // 选取文件超过数量提示
      beforeUpload(file) {
        const format = ['image/png', 'image/jpeg'];
        const size = file.size / 1024 <= 50;
        if (!format.includes(file.type)) {
          this.$message.warning('只支持.jpg .png格式');
          return false;
        }
        if (!size) {
          this.$message.error('上传图片大小不能超过 50kb!');
        }
        return format.includes(file.type) && size;
      },
      brand() {
        const val = this.$store.state.brandInfo;
        const obj = JSON.stringify(val) === '{}';
        this.logoPath = '';
        (this.$refs.form as any).resetFields();
        if (!obj) {
          this.form = {
            brandId: val.id,
            imageUrl: val.imageUrl,
            name: val.name,
            phone: val.phone,
            email: val.email,
            webSite: val.webSite,
            address: val.address,
            summary: val.summary,
            isManufacturer: val.isManufacturer,
            isShow: val.isShow,
            fansCount: val.fansCount,
            productCount: val.productCount,
            certificationIds: val.certifications.length > 0 ? val.certifications.map((i) => i.id) : [],
          };
        }
      },
      async getCertifications() {
        const res = await api.getCertifications();
        if (!api.error(res)) {
          this.certifications = res.data;
        }
      },
      async handleAvatarSuccess(response, file) {
        if (response.success) {
          this.$message.success('添加logo成功');
          this.form.imageUrl = '';
          this.logo = URL.createObjectURL(file.raw);
          this.logoPath = response.items[0].url;
        } else {
          this.$message.error(response.error.message);
        }
      },
      cancel() {
        this.$router.push({ path: '/product/brand' });
      },
      save() {
        this.form.imageUrl = this.logoPath || this.form.imageUrl;
        (this.$refs.form as any).validate(async (valid) => {
          if (valid) {
            const res = await api.putBrand(this.form);
            if (!api.error(res)) {
              this.$message.success('添加成功');
              this.cancel();
            } else {
              this.$message.error(res.error.message);
            }
          } else {
            console.log('error submit!!');
            return false;
          }
          return true;
        });
      },
    },
});

